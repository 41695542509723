<template>
    <a v-if="url" :href="url" target="_blank" rel="noopener noreferrer" class="block anchor-card">
        <Contents :title="title" :description="description" :img="img" :tag="tag" class="thumbnail" />
    </a>
    <NuxtLink v-else-if="to" :to="to" class="anchor-card">
        <Contents :title="title" :description="description" :img="img" :tag="tag" class="thumbnail" />
    </NuxtLink>
    <Contents v-else :title="title" :description="description" :img="img" :tag="tag" />
</template>

<script>
import Contents from './Contents.vue';

export default {
    name: 'LigetCard',
    components: {
        Contents,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        to: {
            type: String,
            required: false,
            default: null,
        },
        slug: {
            type: String,
            required: false,
            default: null,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
        url: {
            type: String,
            required: false,
            default: null,
        },
        img: {
            type: String,
            required: true,
        },
        tag: {
            type: String,
            required: false,
            default: null,
        },
    },
};
</script>

<style scoped>
.anchor-card:hover h3 {
    @apply underline;
}
</style>
