<template>
    <div class="grid grid-cols-12 gap-2">
        <div
            class="thumbnail base-responsive-rounding aspect-h-2 aspect-w-3"
            :class="flexRowOnMobile ? 'col-span-4 sm:col-span-12 h-[140px]' : 'col-span-12'"
        >
            <img v-lazy-load :data-src="img" :alt="title" />
        </div>
        <div :class="flexRowOnMobile ? 'col-span-8 sm:col-span-12' : 'col-span-12'">
            <div class="text-gray-400 font-bold uppercase text-xs sm:text-sm my-2">
                <span>
                    {{ $dayjs(date).format('YYYY/MM/DD') }}
                </span>
                <span v-if="tag" class="inline">
                    <span class="separator-bullet"></span>
                    {{ tag }}
                </span>
            </div>
            <h4 class="font-Poppins text-base sm:text-lg font-semibold sm:line-clamp-2">
                {{ title }}
            </h4>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ArticleCardContents',
    props: {
        title: {
            type: String,
            required: true,
        },
        img: {
            type: String,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
        tag: {
            type: String,
            required: false,
            default: null,
        },
        flexRowOnMobile: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped></style>
