var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-container relative grid grid-cols-1",class:{
        locked: _vm.isCarouselLocked,
        'type-tab-type-1': _vm.type === 'tab-type-1',
        'type-tab-type-2': _vm.type === 'tab-type-2',
        'type-tab-type-3': _vm.type === 'tab-type-3',
        'type-article': _vm.type === 'article',
        'type-program': _vm.type === 'program',
        'type-video': _vm.type === 'video',
        'type-exhibition': _vm.type === 'exhibition',
        'type-sport': _vm.type === 'sport',
    }},[_c('div',{staticClass:"relative",class:[
            _vm.isProgramPageFeaturedCarousel ? 'w-screen -ml-2.5 sm:ml-0 lg:overflow-hidden' : 'overflow-hidden',
            _vm.type === 'tab-type-2' ? 'order-2' : 'order-1 sm:order-2',
        ]},[_c('div',{ref:"swiper",staticClass:"swiper container",class:{
                'lg:max-w-[calc(992px/12*8)] xl:max-w-[calc(((100vw-1140px)/2)+1140px/12*9-2rem)] 2xl:max-w-[calc(((100vw-1440px)/2)+1440px/12*9-2rem)] 3xl:max-w-[calc(70vw/12*9+15vw-2rem)] ml-0':
                    _vm.isProgramPageFeaturedCarousel,
            },staticStyle:{"overflow":"visible"}},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.items),function(item,i){return _c('div',{key:`item-${i}`,staticClass:"swiper-slide",staticStyle:{"height":"auto"},on:{"click":function($event){return _vm.setActiveIndex(i)}}},[(_vm.type === 'tab-type-1' || _vm.type === 'tab-type-3')?_c('TabCard',{attrs:{"type":1,"small":_vm.type === 'tab-type-3',"title":item.title,"slug":item.slug,"anchor":item.anchor,"img":require('~/assets/images/dev/demo3.png'),"active":_vm.isInitialized && _vm.computedActiveSlideIndex === i}}):(_vm.type === 'tab-type-2')?_c('TabCard',{attrs:{"type":2,"title":item.name,"slug":item.slug,"anchor":item.anchor,"img":_vm.$getMediaImage(item.media, 'lead', 'sm'),"active":_vm.isInitialized && _vm.computedActiveSlideIndex === i}}):(_vm.type === 'video')?_c('VideoCard',{attrs:{"date":item.created_at,"img":_vm.$getMediaImage(item.media, 'lead', 'sm'),"title":item.title}}):(_vm.type === 'exhibition')?_c('ExhibitionCard',{attrs:{"date":_vm.$getFormattedEventInterval(item),"img":_vm.$getMediaImage(item.media, 'lead', 'sm'),"title":item.name,"location":_vm.$getEventLocationName(item.location_id),"url":_vm.$getEventUrl(item)}}):(_vm.type === 'sport')?_c('LigetCard',{attrs:{"title":item.name,"img":_vm.$getMediaImage(item.media, 'image', 'sm'),"to":_vm.localePath({
                                name: 'visitor-information-visitorInformation',
                                params: { visitorInformation: item.slug },
                            }),"url":item.url}}):(_vm.type === 'article')?_c('ArticleCard',{attrs:{"date":item.published_at,"img":_vm.$getMediaImage(item.media, 'lead', 'sm'),"slug":item.slug,"tag":_vm.$getNewsCategoryLabel(item.category_id),"title":item.title}}):(_vm.type === 'program')?_c('ProgramRecommendationCard',{attrs:{"date":_vm.$getFormattedEventInterval(item),"img":_vm.$getMediaImage(item.media, 'lead', 'sm'),"location":_vm.$getEventLocationName(item.location_id),"url":_vm.$getEventUrl(item),"title":item.name,"has-series":!!item.series}}):_vm._e()],1)}),0),_vm._v(" "),_c('SwiperNavigationButton',{staticClass:"swiper-nav-btn",attrs:{"prev":"","overflow":!_vm.isProgramPageFeaturedCarousel,"hidden":_vm.isCarouselBeginning},nativeOn:{"click":function($event){return _vm.prev.apply(null, arguments)}}}),_vm._v(" "),_c('SwiperNavigationButton',{staticClass:"swiper-nav-btn",attrs:{"next":"","overflow":!_vm.isProgramPageFeaturedCarousel,"hidden":_vm.isCarouselEnd},nativeOn:{"click":function($event){return _vm.next.apply(null, arguments)}}})],1)]),_vm._v(" "),_c('div',{staticClass:"carousel-pagination-block container sm:-mt-16",class:[_vm.type === 'tab-type-2' ? 'order-1 mb-6' : 'order-2 sm:order-1 mt-6']},[_c('div',{staticClass:"flex items-center sm:justify-end",class:[_vm.type === 'tab-type-2' ? 'justify-end' : 'justify-center']},[_c('div',{ref:"prevBtn",staticClass:"swiper-button-prev"}),_vm._v(" "),_c('span',{staticClass:"gallery-pagination-counter"},[_vm._v("01")]),_vm._v(" "),_c('div',{ref:"pagination",staticClass:"swiper-pagination"}),_vm._v(" "),_c('span',{staticClass:"gallery-pagination-counter"},[_vm._v(_vm._s(String(_vm.items.length).padStart(2, '0')))]),_vm._v(" "),_c('div',{ref:"nextBtn",staticClass:"swiper-button-next"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }