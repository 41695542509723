<template>
    <article class="anchor-card h-full">
        <div class="grid grid-cols-1 gap-2">
            <div class="thumbnail base-responsive-rounding aspect-h-2 aspect-w-3">
                <img :src="img" :alt="title" />
                <div
                    class="absolute w-8 h-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-contain bg-center bg-no-repeat"
                    :style="`background-image: url(${require('~/assets/images/icons/yt-play-icon.png')})`"
                ></div>
            </div>
            <div>
                <div class="text-gray-400 font-bold uppercase text-xs sm:text-sm my-2">
                    <span>
                        {{ $dayjs(date).format('YYYY/MM/DD') }}
                    </span>
                </div>
                <h3 class="font-Poppins text-base sm:text-lg font-semibold line-clamp-3 sm:line-clamp-2">
                    {{ title }}
                </h3>
            </div>
        </div>
    </article>
</template>

<script>
export default {
    name: 'VideoCard',
    props: {
        title: {
            type: String,
            required: true,
        },
        img: {
            type: String,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
        flexRowOnMobile: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped></style>
