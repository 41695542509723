<template>
    <div
        class="swiper-container relative grid grid-cols-1"
        :class="{
            locked: isCarouselLocked,
            'type-tab-type-1': type === 'tab-type-1',
            'type-tab-type-2': type === 'tab-type-2',
            'type-tab-type-3': type === 'tab-type-3',
            'type-article': type === 'article',
            'type-program': type === 'program',
            'type-video': type === 'video',
            'type-exhibition': type === 'exhibition',
            'type-sport': type === 'sport',
        }"
    >
        <div
            class="relative"
            :class="[
                isProgramPageFeaturedCarousel ? 'w-screen -ml-2.5 sm:ml-0 lg:overflow-hidden' : 'overflow-hidden',
                type === 'tab-type-2' ? 'order-2' : 'order-1 sm:order-2',
            ]"
        >
            <div
                ref="swiper"
                class="swiper container"
                :class="{
                    'lg:max-w-[calc(992px/12*8)] xl:max-w-[calc(((100vw-1140px)/2)+1140px/12*9-2rem)] 2xl:max-w-[calc(((100vw-1440px)/2)+1440px/12*9-2rem)] 3xl:max-w-[calc(70vw/12*9+15vw-2rem)] ml-0':
                        isProgramPageFeaturedCarousel,
                }"
                style="overflow: visible"
            >
                <div class="swiper-wrapper">
                    <div
                        v-for="(item, i) in items"
                        :key="`item-${i}`"
                        class="swiper-slide"
                        style="height: auto"
                        @click="setActiveIndex(i)"
                    >
                        <TabCard
                            v-if="type === 'tab-type-1' || type === 'tab-type-3'"
                            :type="1"
                            :small="type === 'tab-type-3'"
                            :title="item.title"
                            :slug="item.slug"
                            :anchor="item.anchor"
                            :img="require('~/assets/images/dev/demo3.png')"
                            :active="isInitialized && computedActiveSlideIndex === i"
                        />
                        <TabCard
                            v-else-if="type === 'tab-type-2'"
                            :type="2"
                            :title="item.name"
                            :slug="item.slug"
                            :anchor="item.anchor"
                            :img="$getMediaImage(item.media, 'lead', 'sm')"
                            :active="isInitialized && computedActiveSlideIndex === i"
                        />
                        <VideoCard
                            v-else-if="type === 'video'"
                            :date="item.created_at"
                            :img="$getMediaImage(item.media, 'lead', 'sm')"
                            :title="item.title"
                        />
                        <ExhibitionCard
                            v-else-if="type === 'exhibition'"
                            :date="$getFormattedEventInterval(item)"
                            :img="$getMediaImage(item.media, 'lead', 'sm')"
                            :title="item.name"
                            :location="$getEventLocationName(item.location_id)"
                            :url="$getEventUrl(item)"
                        />
                        <LigetCard
                            v-else-if="type === 'sport'"
                            :title="item.name"
                            :img="$getMediaImage(item.media, 'image', 'sm')"
                            :to="
                                localePath({
                                    name: 'visitor-information-visitorInformation',
                                    params: { visitorInformation: item.slug },
                                })
                            "
                            :url="item.url"
                        />
                        <ArticleCard
                            v-else-if="type === 'article'"
                            :date="item.published_at"
                            :img="$getMediaImage(item.media, 'lead', 'sm')"
                            :slug="item.slug"
                            :tag="$getNewsCategoryLabel(item.category_id)"
                            :title="item.title"
                        />
                        <ProgramRecommendationCard
                            v-else-if="type === 'program'"
                            :date="$getFormattedEventInterval(item)"
                            :img="$getMediaImage(item.media, 'lead', 'sm')"
                            :location="$getEventLocationName(item.location_id)"
                            :url="$getEventUrl(item)"
                            :title="item.name"
                            :has-series="!!item.series"
                        />
                    </div>
                </div>

                <SwiperNavigationButton
                    prev
                    :overflow="!isProgramPageFeaturedCarousel"
                    :hidden="isCarouselBeginning"
                    class="swiper-nav-btn"
                    @click.native="prev"
                />
                <SwiperNavigationButton
                    next
                    :overflow="!isProgramPageFeaturedCarousel"
                    :hidden="isCarouselEnd"
                    class="swiper-nav-btn"
                    @click.native="next"
                />
            </div>
        </div>

        <div
            class="carousel-pagination-block container sm:-mt-16"
            :class="[type === 'tab-type-2' ? 'order-1 mb-6' : 'order-2 sm:order-1 mt-6']"
        >
            <div
                class="flex items-center sm:justify-end"
                :class="[type === 'tab-type-2' ? 'justify-end' : 'justify-center']"
            >
                <div ref="prevBtn" class="swiper-button-prev"></div>
                <span class="gallery-pagination-counter">01</span>
                <div ref="pagination" class="swiper-pagination"></div>
                <span class="gallery-pagination-counter">{{ String(items.length).padStart(2, '0') }}</span>
                <div ref="nextBtn" class="swiper-button-next"></div>
            </div>
        </div>
    </div>
</template>

<script>
import SwiperNavigationButton from '@/components/UI/SwiperNavigationButton.vue';
import TabCard from '@/components/Cards/TabCard/TabCard.vue';
import ArticleCard from '@/components/Cards/ArticleCard/ArticleCard.vue';
import VideoCard from '@/components/Cards/VideoCard/VideoCard.vue';
import ProgramRecommendationCard from '@/components/Cards/ProgramRecommendationCard/ProgramRecommendationCard.vue';
import ExhibitionCard from '~/components/Cards/ExhibitionCard/ExhibitionCard.vue';
import LigetCard from '~/components/Cards/LigetCard/LigetCard.vue';

export default {
    name: 'CardCarousel',
    components: {
        ExhibitionCard,
        LigetCard,
        ProgramRecommendationCard,
        ArticleCard,
        VideoCard,
        SwiperNavigationButton,
        TabCard,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        type: {
            type: String,
            required: true,
            validator(value) {
                if (
                    ![
                        'article',
                        'program',
                        'video',
                        'exhibition',
                        'sport',
                        'tab-type-1',
                        'tab-type-2',
                        'tab-type-3',
                    ].includes(value)
                ) {
                    throw new Error('Invalid CardCarousel type');
                }
                return true;
            },
        },
        isProgramPageFeaturedCarousel: {
            type: Boolean,
            required: false,
            default: false,
        },
        isInitialized: {
            type: Boolean,
            required: false,
            default: true,
        },
        programmaticActiveSlideIndex: {
            type: Number,
            required: false,
            default: null,
        },
        shouldUpdateActiveIndexOnSlideChange: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            activeSlideIndex: 0,
            carousel: null,
            isCarouselLocked: false,
            isCarouselBeginning: true,
            isCarouselEnd: false,
        };
    },
    computed: {
        computedActiveSlideIndex() {
            if (this.programmaticActiveSlideIndex !== null) {
                return this.programmaticActiveSlideIndex;
            } else {
                return this.activeSlideIndex;
            }
        },
    },
    mounted() {
        const breakpoints = {
            'tab-type-1': {
                480: {
                    spaceBetween: 16,
                    slidesPerView: 2.2,
                },
                640: {
                    spaceBetween: 16,
                    slidesPerView: 3,
                },
                1024: {
                    spaceBetween: 30,
                    slidesPerView: 4,
                },
                1280: {
                    spaceBetween: 30,
                    slidesPerView: 4,
                },
            },
            'tab-type-2': {
                480: {
                    spaceBetween: 16,
                    slidesPerView: 2.2,
                },
                768: {
                    spaceBetween: 16,
                    slidesPerView: 3,
                },
                1024: {
                    spaceBetween: 30,
                    slidesPerView: 4,
                },
                1280: {
                    spaceBetween: 30,
                    slidesPerView: 4,
                },
            },
            'tab-type-3': {
                480: {
                    spaceBetween: 16,
                    slidesPerView: 2.2,
                },
                640: {
                    spaceBetween: 16,
                    slidesPerView: 3,
                },
                1024: {
                    spaceBetween: 30,
                    slidesPerView: 4,
                },
                1280: {
                    spaceBetween: 30,
                    slidesPerView: 5,
                },
            },
            article: {
                640: {
                    spaceBetween: 16,
                    slidesPerView: 2.2,
                },
                768: {
                    spaceBetween: 16,
                    slidesPerView: 3,
                },
                1024: {
                    spaceBetween: 30,
                    slidesPerView: 3,
                },
            },
            program: {
                640: {
                    spaceBetween: 16,
                    slidesPerView: 2.2,
                },
                768: {
                    spaceBetween: 16,
                    slidesPerView: 3,
                },
                1024: {
                    spaceBetween: 30,
                    slidesPerView: 3,
                },
            },
            video: {
                480: {
                    spaceBetween: 16,
                    slidesPerView: 2.2,
                },
                640: {
                    spaceBetween: 16,
                    slidesPerView: 3,
                },
                1024: {
                    spaceBetween: 30,
                    slidesPerView: 4,
                },
            },
            sport: {
                640: {
                    spaceBetween: 16,
                    slidesPerView: 2.2,
                },
                768: {
                    spaceBetween: 16,
                    slidesPerView: 3,
                },
                1024: {
                    spaceBetween: 30,
                    slidesPerView: 3,
                },
            },
            exhibition: {
                480: {
                    spaceBetween: 16,
                    slidesPerView: 2.2,
                },
                640: {
                    spaceBetween: 16,
                    slidesPerView: 3.2,
                },
                1024: {
                    spaceBetween: 20,
                    slidesPerView: 3,
                },
                1280: {
                    spaceBetween: 30,
                    slidesPerView: 4.5,
                },
            },
        };

        this.carousel = new this.$swiper(this.$refs.swiper, {
            spaceBetween: 16,
            slidesPerView: 1.4,
            centeredSlides: false,
            loop: false,
            watchOverflow: true,
            navigation: {
                nextEl: this.$refs.nextBtn,
                prevEl: this.$refs.prevBtn,
            },
            pagination: {
                el: this.$refs.pagination,
                clickable: true,
            },
            breakpoints: breakpoints[this.type],
        });

        this.isCarouselLocked = this.carousel.isLocked;

        this.carousel.on('lock', () => {
            this.isCarouselLocked = true;
        });
        this.carousel.on('unlock', () => {
            this.isCarouselLocked = false;
        });

        this.carousel.on('slideChange', () => {
            this.isCarouselBeginning = this.carousel.isBeginning;
            this.isCarouselEnd = this.carousel.isEnd;

            if (this.shouldUpdateActiveIndexOnSlideChange) {
                this.activeSlideIndex = this.carousel.realIndex;
                this.$emit('update-active-index', this.carousel.realIndex);
            }
        });

        let itemWithRouteHash;

        if (this.$route.hash && this.items.find((x) => x.anchor === this.$route.hash)) {
            itemWithRouteHash = this.items.find((x) => x.anchor === this.$route.hash);
            const index = this.items.indexOf(itemWithRouteHash);
            this.setActiveIndex(index);
        }
    },
    methods: {
        setActiveIndex(i) {
            this.activeSlideIndex = i;

            if (this.shouldUpdateActiveIndexOnSlideChange) {
                this.carousel.slideTo(i);
            }

            this.$emit('update-active-index', i);
        },
        prev() {
            this.carousel.slidePrev();
        },
        next() {
            this.carousel.slideNext();
        },
    },
};
</script>

<style scoped>
.type-tab-type-2,
.type-video,
.type-sport {
    .swiper-nav-btn {
        @apply top-[5.5rem] md:top-[5rem] lg:top-[4.75rem] xl:top-[5.5rem] 2xl:top-[7rem] 3xl:top-[7rem];
    }
}

.type-article,
.type-program {
    .swiper-nav-btn {
        @apply top-[5.25rem] md:top-[4.75rem] lg:top-[6.5rem] xl:top-[7.5rem] 2xl:top-[10rem] 3xl:top-[9rem];
    }
}

.type-exhibition .swiper-nav-btn {
    @apply top-[5.25rem] sm:top-[7.5rem] md:top-[9.5rem] lg:top-[8.5rem] xl:top-[9vw] 2xl:top-[9.5vw];
}
</style>
