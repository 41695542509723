<template>
    <article class="anchor-card h-full">
        <a v-if="url" :href="url" target="_blank" rel="noopener noreferrer" class="block">
            <Contents :title="title" :img="img" :tag="tag" :date="date" :flex-row-on-mobile="flexRowOnMobile" />
        </a>
        <NuxtLink
            v-else
            :to="
                localePath({
                    name: archive ? 'archive-article' : 'magazine-article',
                    params: { article: slug },
                })
            "
            class="block"
        >
            <Contents :title="title" :img="img" :tag="tag" :date="date" :flex-row-on-mobile="flexRowOnMobile" />
        </NuxtLink>
    </article>
</template>

<script>
import Contents from './Contents.vue';
export default {
    name: 'ArticleCard',
    components: {
        Contents,
    },
    props: {
        archive: {
            type: Boolean,
            required: false,
            default: false,
        },
        slug: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        img: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: false,
            default: null,
        },
        date: {
            type: String,
            required: true,
        },
        tag: {
            type: String,
            required: false,
            default: null,
        },
        flexRowOnMobile: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped></style>
