<template>
    <div
        v-if="type === 1"
        :id="computedID"
        class="type-1 thumbnail base-responsive-rounding bg-cover bg-center"
        :class="{ active: active, 'py-10 text-base lg:text-lg': !small, 'py-8': small }"
        v-lazy-background :lazy-background="img"
    >
        <span>{{ title }}</span>
    </div>
    <div v-else :id="computedID" class="type-2" :class="{ active: active }">
        <div class="thumbnail base-responsive-rounding aspect-w-3 aspect-h-2 mb-4">
            <img v-lazy-load :data-src="img" :alt="title" />
        </div>
        <span>{{ title }}</span>
    </div>
</template>

<script>
export default {
    name: 'TabCard',
    props: {
        // type: 1: text goes on top of image, blue bg opacity overlay on hover & active state
        // type: 2: text goes under image, underlined text on hover & active state
        type: {
            type: Number,
            required: false,
            default: 1,
            validator(value) {
                if (![1, 2].includes(value)) {
                    throw new Error('TabCard can either have type 1 or type 2');
                }
                return true;
            },
        },
        title: {
            type: String,
            required: true,
        },
        slug: {
            type: String,
            required: false,
            default: null,
        },
        anchor: {
            type: String,
            required: false,
            default: null,
        },
        img: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            required: false,
            default: false,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        computedID() {
            if (!this.anchor) {
                return null;
            }

            const id = this.anchor.replace(/^#/, '');

            return `cc___${id}`;
        },
    },
};
</script>

<style scoped>
.type-1 {
    @apply font-Poppins font-semibold uppercase text-white text-center;
    @apply w-full h-full px-4 flex items-center justify-center;
    &::before {
        content: '';
        @apply block absolute top-0 left-0 w-full h-full bg-black/40 z-0 transition-colors pointer-events-none;
    }
    &.active::before {
        @apply bg-blue-500/80;
    }
    @media (hover: hover) {
        &:hover::before {
            @apply bg-blue-500/80;
        }
    }
    span {
        @apply relative z-1;
    }
}

.type-2 {
    @apply pb-2;
    span {
        @apply inline relative font-Poppins lg:text-lg uppercase mt-4;
        line-height: 1.9rem;
        border-bottom-width: 3px;
        @apply border-transparent;
    }

    &.active span {
        @apply font-bold border-yellow-500;
    }
    @media (hover: hover) {
        &:hover span {
            @apply border-yellow-500 cursor-pointer;
        }
    }
}
</style>
