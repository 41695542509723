<template>
    <article class="h-full">
        <a v-if="url" :href="url" target="_blank" class="block anchor-card exhibition-card">
            <div class="thumbnail base-responsive-rounding aspect-h-4 aspect-w-3 mb-5">
                <img v-lazy-load :data-src="img" :alt="title" />
                <div class="exhibition-card__img-hover-overlay bg-theme-dark flex items-center justify-center">
                    <Button
                        :label="$t('generic.read_more')"
                        class="p-button-secondary p-button-text pointer-events-none"
                        icon="pi pi-chevron-right"
                    />
                </div>
            </div>
            <div class="font-Poppins font-semibold text-sm sm:text-base uppercase">{{ date }}</div>
            <div v-if="location" class="uppercase">{{ location }}</div>
            <div class="exhibition-card__separator-line my-2"></div>
            <h3 class="font-Poppins text-base sm:text-lg font-semibold sm:line-clamp-3">
                {{ title }}
            </h3>
        </a>
    </article>
</template>

<script>
export default {
    name: 'ExhibitionCard',
    props: {
        title: {
            type: String,
            required: true,
        },
        img: {
            type: String,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
        location: {
            type: String,
            required: false,
            default: null,
        },
        url: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.exhibition-card {
    @media (hover: hover) {
        &:hover {
            .exhibition-card__img-hover-overlay {
                opacity: 1;
            }
        }
    }
}
.exhibition-card__separator-line {
    height: 1px;
    background-color: black;
}
.exhibition-card__img-hover-overlay {
    @apply opacity-0 transition-opacity;
    background: rgba(24, 91, 191, 0.7);
}
</style>
