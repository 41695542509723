<template>
    <div>
        <div class="aspect-w-3 aspect-h-2 base-responsive-rounding">
            <img :src="img" alt="" class="w-full h-full object-cover" />
        </div>

        <h3 class="font-Poppins font-semibold text-xl uppercase mt-4">
            {{ title }}
        </h3>

        <p v-if="description" class="mt-1">{{ description }}</p>

        <div v-if="tag" class="relative -mt-1">
            <div class="inline-block border-t border-black w-20"></div>
            <div class="uppercase mt-1">{{ tag }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LigetCardContents',
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
        img: {
            type: String,
            required: true,
        },
        tag: {
            type: String,
            required: false,
            default: null,
        },
    },
};
</script>

<style scoped></style>
