<template>
    <button
        class="custom-swiper-navigation-button absolute hidden sm:inline-flex p-2 bg-white rounded-full items-center justify-center top-1/2 -translate-y-1/2 z-2"
        :class="{
            'left-[5vw]': !overflow && prev,
            'right-[5vw]': !overflow && next,
            'left-1/2 translate-x-[calc(-50%-50vw+5rem)]': overflow && prev,
            'right-1/2 translate-x-[calc(50%+50vw-5rem)]': overflow && next,
        }"
        :style="hidden ? 'display: none' : ''"
    >
        <i class="pi font-bold text-black" :class="{ 'pi-chevron-left ': prev, 'pi-chevron-right': next }"></i>
    </button>
</template>

<script>
// This component is meant to be used as a direct child of .swiper (because it needs to be vertically positioned in its middle)
export default {
    name: 'SwiperNavigationButton',
    props: {
        prev: {
            type: Boolean,
            required: false,
            default: false,
        },
        next: {
            type: Boolean,
            required: false,
            default: false,
        },
        hidden: {
            type: Boolean,
            required: false,
            default: false,
        },
        overflow: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style>
.swiper-container.locked .custom-swiper-navigation-button {
    display: none;
}
</style>
